:root {
  --color-primary: #862d90;
  --color-secondary: #6fccd4;
  --color-action: #ec2994;
  --text-color: #363636;
  --bg-color: #fff;
  --font-size: 16px;
  --font-family-sans-serif: "Montserrat", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

html,
body {
  background-color: var(--bg-color);
  color: var(--color-primary);
  height: 100%;
  font-size: var(--font-size);
  font-family: var(--font-family-sans-serif);
  line-height: 1.5;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

h1 {
  font-size: 5rem;
  line-height: 0.8;
  font-weight: 300;
}

h2 {
  font-size: 3rem;
  line-height: 1;
  font-weight: 500;
}

h3 {
  font-size: 2.5rem;
  line-height: 0.8;
  font-weight: 600;
}

h4 {
  font-size: 2rem;
  line-height: 1;
  font-weight: 700;
}

h5 {
  font-size: 1.5rem;
  line-height: 0.8;
  font-weight: 800;
}

h6 {
  font-size: 1rem;
  line-height: 1;
  font-weight: 900;
}

ul {
  padding-left: 1.5rem;
}

a {
  color: inherit;
  text-decoration: none;
  color: var(--color-action);
  &:hover {
    border-bottom: 1px solid var(--color-action);
  }
}

#root {
  height: 100%;
}

.unstyled-list {
  list-style: none;
  padding-left: 0;
}

.emoji {
  font-size: 1rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.container {
  padding-left: 1rem;
  padding-right: 1rem;
}
